<template>
  <section>
    <b-row>
      <b-col>
        <b-form-group class="containerInputDocumentosCampanha">
          <label :for="'archive-import-colaborador-grupo-'+grupo.id_grupo+'-vacina-'+vacina.id_vacina"
            class="
              inputUploadDocument 
              rounded
              d-flex
              align-items-center justify-content-center
            "> 
            <div class="d-flex flex-column align-items-center">
              <div>
                <feather-icon icon="DownloadCloudIcon" size="18"/>
                <span>Selecione os arquivos</span> 
              </div>

              <p class="infoDocuments font-italic"><b>Tamanho</b>: Até 10MB | <b>Tipo</b>: PDF</p>
            </div>
          </label>
          <b-form-file
            :key="updatedAt"
            v-model="documentoSelecionado"
            :id="'archive-import-colaborador-grupo-'+grupo.id_grupo+'-vacina-'+vacina.id_vacina"
            size="lg"
            placeholder="Selecione os arquivos"
            drop-placeholder="Insira o arquivo aqui..."
            browse-text="Escolher arquivo"
            variant="primary"
            accept=".pdf"
            class="inputFileCampanhaDocuments"
          />
          <small v-if="error" class="text-danger">
            {{ error }}
          </small>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="customPaddingRow">
      <b-col
        cols="12"
        v-for="documento in documentos"
        :key="documento.id_campanha_grupo_documento"
        class="d-flex justify-content-between align-items-center backgroundCustomFile rounded ml-1"
      >
        <span style="align-self: flex-start">{{ documento.descricao }}</span>
        <div>
          <feather-icon
            @click="visualizar(documento)"
            icon="DownloadIcon"
            class="cursor-pointer mr-1"
            size="18"
          />
          <feather-icon
            @click="deletar(documento)"
            icon="XIcon"
            class="cursor-pointer"
            size="18"
          />
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormFile } from "bootstrap-vue";
import {
  CampanhaNacionalSetDocumentoVacinaGrupo,
  CampanhaNacionalGetDocumentoVacinaGrupo,
  CampanhaNacionalGetState,
  CampanhaNacionalDeleteDocumentoVacinaGrupo,
} from "@/views/custom-pages/campanha-nacional/Helpers/campanhaNacionalHelpers.js";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
  },
  props: {
    vacina: {
      type: Object,
      required: true,
    },
    grupo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      documentos: [],
      documentoSelecionado: null,
      error: null,
      maxSize: 10000000,
      updatedAt: null,
    };
  },
  methods: {
    validationSizeFile(size) {
      this.error = null;
      if (this.maxSize < size) {
        this.error = "O tamanho do arquivo excede 10mb.";
        return false;
      }
      return true;
    },
    handleFileChange(selectedFile) {
      if (selectedFile && selectedFile.type == "application/pdf") {
        if (this.validationSizeFile(selectedFile.size)) {
          CampanhaNacionalSetDocumentoVacinaGrupo(
            this.vacina.id_vacina,
            this.grupo.id_grupo,
            selectedFile
          );
          this.documentoSelecionado = null;
        }
      }
    },
    visualizar(documento) {
      this.$http
        .get(this.$api.getAnexo(documento.id_anexo), {responseType: 'arraybuffer'})
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", documento.descricao);
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
    },
    deletar(documento) {
      CampanhaNacionalDeleteDocumentoVacinaGrupo(
        documento.id_vacina,
        documento.id_grupo,
        documento.id_grupo_documento
      );
    },
  },
  watch: {
    documentoSelecionado(selectedFile) {
      if (selectedFile != null) {
        this.handleFileChange(selectedFile);
      }
    },
    "$store.state.campanhaNacionalState.watchers.documentos": {
      immediate: true,
      handler() {
        this.documentos = CampanhaNacionalGetDocumentoVacinaGrupo(
          this.vacina.id_vacina,
          this.grupo.id_grupo
        );
        this.updatedAt = CampanhaNacionalGetState().updatedAt;
      },
    },
  },
};
</script>

<style>
  .inputFileCampanhaDocuments > label{
    opacity: 0;
    border: 1px solid red;
    position: absolute;
    top: -80px;
    width: 100%;
    min-height: 76px;
  }

  .inputUploadDocument {
    background: #2772C00F;
    border: 1px dashed #2772C0;
    width: 100%;
    height: 75px;
    cursor: pointer;
    color: #2772C0;
  }

  .inputUploadDocument svg {
    margin-right: 10px;
  }

  .inputUploadDocument span {
    font-size: 1rem;
    text-decoration: underline;
  }

  .inputUploadDocument .infoDocuments {
    color: #5E5873;
    margin: 0;
    margin-top: 5px;
    font-size: 0.9rem;
  }

  .backgroundCustomFile {
    background: #BABFC71F;
    padding: 5px 10px 5px 10px;
    margin-bottom: 10px;
    position: relative;
    bottom: 40px;
  }

  .customPaddingRow {
    padding-right: 2rem;
  }

  label.custom-file-label {
    cursor: pointer;
  }
</style>
