<template>
  <b-row class="border-bottom my-1">
    <b-col class="col align-middle">
      <h4>{{ departamento.sigla }}</h4>
    </b-col>
    <b-col class="col-auto">
      <b-form-group>
        <label class="customLabelQtdDistribuicao" :for="targetForIdFocus">Qtd de Doses:</label>
        <b-input-group>
          <b-input-group-prepend>
            <div class="d-flex align-items-center 
              custom-append-input 
              iconInputQtdDosesDistribuicao
            ">
               <img
                class="text-custom-blue customImageVacinaDistribuicao mr-1"
                :src="VaccineIcon"
                alt="vaccineIcon"
                width="14"
                height="14"
              />
            </div>
          </b-input-group-prepend>
          <b-form-input
            class="inputQtdDosesDistribuicao"
            v-model="qtd"
            :id="targetForIdFocus" 
            placeholder="0"
            @keypress="onlyNumber"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BFormInput, BFormGroup, BInputGroup, BRow, BInputGroupPrepend } from "bootstrap-vue";
import {
  CampanhaNacionalSetQtdDistribuicaoVacinaGrupoOperador,
  CampanhaNacionalGetQtdDistribuicaoVacinaGrupoOperador,
} from "@/views/custom-pages/campanha-nacional/Helpers/campanhaNacionalHelpers.js";
import VaccineIcon from "@/assets/custom-icons/vaccine.svg";

import helpers from "@/helpers";
export default {
  setup() {
    return {
      VaccineIcon,
    };
  },
  components: {
    BCol,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BRow,
  },
  props: {
    vacina: {
      type: Object,
      required: true,
    },
    grupo: {
      type: Object,
      required: true,
    },
    operador: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      targetForIdFocus: helpers.geraNumeroAleatorio().toString(),
    };
  },
  methods: {
    onlyNumber(event) {
      if (!helpers.keyIsNumber(event)) {
        event.preventDefault();
      }
    },
    setQtd(qtd) {
      let idVacina = this.vacina.id_vacina,
        idGrupo = this.grupo.id_grupo,
        idDepartamento = this.departamento.id_departamento;

      CampanhaNacionalSetQtdDistribuicaoVacinaGrupoOperador(
        idVacina,
        idGrupo,
        idDepartamento,
        qtd
      );

      this.$emit("input", this.getQtd());
    },
    getQtd() {
      let idVacina = this.vacina.id_vacina,
        idGrupo = this.grupo.id_grupo,
        idDepartamento = this.departamento.id_departamento;

      const item = CampanhaNacionalGetQtdDistribuicaoVacinaGrupoOperador(
        idVacina,
        idGrupo,
        idDepartamento
      )

      return item.qtd_dose == 0 ? null : item.qtd_dose;
    },
  },
  computed: {
    departamento() {
      return this.operador.departamento;
    },
    qtd: {
      set(value) {
        this.setQtd(Number(value));
      },
      get() {
        return this.getQtd();
      },
    },
  },
};
</script>
<style>
  .inputQtdDosesDistribuicao {
    max-width: 70px;
    max-height: 36px;
    background: #2772C00A;
    border-color: #2772C0;
    border-left: none;
    padding: 5px 5px 5px 12px; 
  }

  .inputQtdDosesDistribuicao:focus {
    background: #2772C00A;
  }

  .iconInputQtdDosesDistribuicao {
    max-width: 30px;
    max-height: 36px;
    margin-right: 1px;
    background: #2772C00A;
    border: 1px solid #2772C0;
    border-color: #2772C0;
    border-right: none;
    border-radius: 5px 0 0 5px;
  }

  .iconInputQtdDosesDistribuicao > img {
    margin-left: 8px;
  }

  .customLabelQtdDistribuicao {
    font-size: 0.7rem;
    margin: 0 10px 0 12px;
  }
</style>

