<template>
  <div id="form-tabs-grupo-precificacao-distribuicao">
    <form-wizard
      ref="formTabsGrupoPrecificacaoDistribuicao"
      color="#2772C0"
      :title="null"
      :subtitle="null"
      finish-button-text0="Submit"
      back-button-text="Previous"
      class="steps-transparent mb-3"
      @on-change="changeTab"
    >
      <tab-content
        title="Grupos"
        icon="feather icon-database"
        :before-change="() => blockAdvanceStep()"
      >
        <VaccineGroupForm
          @errorValidationPrecification="handleErrorValidationPrecification"
        />
      </tab-content>
      <tab-content
        title="Precificação"
        icon="feather icon-dollar-sign"
        :before-change="() => blockAdvanceStep()"
      >
        <p>Precificação</p>
      </tab-content>
      <tab-content
        title="Distribuição"
        icon="feather icon-disc"
        :before-change="() => blockAdvanceStep()"
      >
        <p>Distribuição</p>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { getInfoUserDepartamento } from '@core/utils/store/getStore'
import CustomAlert from '@/views/components/custom/timed-alert/CustomAlert.vue'
import VaccineGroupForm from '../Forms/VaccineGroupForm.vue'

import { busEvent } from '@/main'

export default {
  components: {
    FormWizard,
    TabContent,
    BButton,
    CustomAlert,
    VaccineGroupForm,
  },

  props: {
    idVaccine: {
      required: true,
      type: Number,
    },
    vaccinesEdited: {
      required: true,
    },
    incentives: {
      required: true,
      type: Object | null,
    },
    numberOfDoses: {
      required: true,
      type: Object | null,
    },
  },

  data() {
    return {
      idVaccineSelected: this.$props.idVaccine,
      selectedIncentives: this.$props.incentives,
      hasPromotion: false,
      hasRegion: !!getInfoUserDepartamento('divisao_por_regiao'),
      selectedNumberOfDoses: this.$props.numberOfDoses,
      vaccinesEditedSelected: this.$props.vaccinesEdited,
      disabledButtonWhenError: true,
      hasRegionDistribution: false,
      liberarUnidades: false,
      showButtom: true,
      disabledButtonStep: false,
      selectedRegioes: null,
      validDistributionUnit: true,
      validDistributionRegion: true,
      showAlert: false,
      noRegion: false,
    }
  },

  watch: {
    idVaccine(IdVaccine) {
      this.idVaccineSelected = IdVaccine
    },

    vaccinesEdited(vaccines) {
      this.vaccinesEditedSelected = vaccines
    },

    numberOfDoses: {
      handler(newValue) {
        this.selectedNumberOfDoses = newValue
      },
      deep: true,
    },

    incentives(incentives) {
      this.selectedIncentives = incentives

      this.fillIncentive()
    },
  },

  mounted() {
    if (this.selectedIncentives) {
      this.fillIncentive()
    }
    const sheet = window.document.styleSheets[0]
    if (!this.hasRegion) {
      sheet.insertRule(
        '.wizard-nav.wizard-nav-pills > li:nth-of-type(2) {display: none;}',
        sheet.cssRules.length,
      )
    } else {
      sheet.insertRule(
        '.wizard-nav.wizard-nav-pills > li:nth-of-type(2) {display: block;}',
        sheet.cssRules.length,
      )
    }

    busEvent.$on('hasDistribution', this.handleHasDistribution)
  },

  beforeDestroy() {
    busEvent.$off('hasDistribution', this.handleHasDistribution)
  },

  methods: {
    proximaPagina(prop) {
      prop.nextTab()
      if (!this.hasRegion && prop.activeTabIndex == 0) {
        prop.nextTab()
      }
    },

    changeTab(_prevIndex, nextIndex) {
      if (nextIndex == 0) {
        this.liberarUnidades = false
        this.disabledButtonStep = false
        this.showButtom = true
      }
      if (nextIndex == 1) {
        busEvent.$emit('checkHasDistributionRegion', this.idVaccineSelected)

        this.disabledButtonStep = true
        if (this.hasRegionDistribution) {
          this.disabledButtonStep = false
        }
        this.liberarUnidades = true
        this.showButtom = true
      }
      if (nextIndex == 2) {
        this.showButtom = false
      }
    },

    handleErroDoses(idVaccine) {
      this.$emit('errorNumberDoses', idVaccine)
    },

    handleErrorValidationPrecification(error) {
      this.disabledButtonWhenError = error
    },

    fillIncentive() {
      const promotion = this.selectedIncentives.filter(
        inccentive => inccentive.nome !== 'Normal',
      )

      if (promotion.length > 0) {
        this.hasPromotion = true
      }
    },

    handleNoRegion() {
      this.noRegion = true
      this.showAlert = true
      this.$emit('noRegion')
    },

    handleNoUnit() {
      this.$emit('noUnit')
    },

    handleRegionDistributionUnidade(regions) {
      this.selectedRegioes = regions[0]

      if (this.$refs.formTabsGrupoPrecificacaoDistribuicao.activeTabIndex == 0) {
        return
      }

      let onlyReservation = false

      if (regions[0].length == 0) {
        onlyReservation = true
      }

      if (regions[1] || onlyReservation) {
        this.disabledButtonStep = true
        busEvent.$emit('canSaveSchedulePrice', [false, this.idVaccineSelected])
      } else {
        this.disabledButtonStep = false
        busEvent.$emit('canSaveSchedulePrice', [true, this.idVaccineSelected])
      }
    },

    handleValidDistribution() {
      this.$emit('validDoseDistribution', {
        idVaccine: this.idVaccineSelected,
        isValid: this.validDistributionUnit && this.validDistributionRegion,
      })
    },

    setStatusRegionDistribution(isValid) {
      this.validDistributionRegion = isValid
    },

    setStatusUnitDistribution(isValid) {
      this.validDistributionUnit = isValid
    },

    blockAdvanceStep() {
      return !(this.disabledButtonWhenError || this.disabledButtonStep)
    },

    handleHasDistribution(distribution) {
      if (distribution.vaccineId == this.idVaccineSelected) {
        this.hasRegionDistribution = distribution.hasDistribution
      }
    },
  },
}
</script>
