<template>
  <div class="content-wrapper" id="campanha-nacional-registro-cadastro">
    <PageHeader screenName="Cadastrar Campanha" :linkItems="linkItems" />

    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <InfosCampanhaForm />

      <section v-if="preencheuDadosMinimos">
        <GroupPricingDoseDistribuition :vacinas="vacinas" />
        <BottomButtons />
      </section>
    </b-overlay>
  </div>
</template>

<script>
import { BOverlay } from "bootstrap-vue";
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
import GroupPricingAndDoseDistribution from "./FormGroups/GroupPricingAndDoseDistribution.vue";
import DepartamentoModal from "@/views/components/custom/modals/ModalErrorDepartamento.vue";
import ScheduleModal from "@/views/custom-pages/Campanhas/Schedule/CampaignScheduleRegister.vue";
import SucessoModal from "@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue";
import ModalShowError from "@/views/components/custom/modals/ModalShowError.vue";
import BottomButtons from "@/views/components/custom/ButtonsCampanhaNacional/BottomButtons.vue";
import InfosCampanhaForm from "@/views/custom-pages/campanha-nacional/RegisterCampanha/Forms/InfosCampanhaForm.vue";
import { getUserData } from "@/auth/utils";

import {
  CampanhaNacionalGetState,
  CampanhaNacionalSetInit,
  CampanhaNacionalGetPreencheuDadosMinimos,
  CampanhaNacionalSetFormDoencas,
  CampanhaNacionalSetFormGrupos,
  CampanhaNacionalGetVacinasSelecionada,
} from "@/views/custom-pages/campanha-nacional/Helpers/campanhaNacionalHelpers.js";

import GroupPricingDoseDistribuition from "./FormGroups/GroupPricingDoseDistribuition.vue";
export default {
  title: "Cadastrar Campanha",

  components: {
    BOverlay,
    PageHeader,
    GroupPricingAndDoseDistribution,
    SucessoModal,
    ScheduleModal,
    DepartamentoModal,
    BottomButtons,
    ModalShowError,
    InfosCampanhaForm,
    GroupPricingDoseDistribuition,
  },

  data() {
    return {
      linkItems: [
        {
          name: "Campanhas",
          routeName: "campanha-list-nacional",
        },
        {
          name: "Cadastrar Campanha",
          active: true,
        },
      ],
      userData: getUserData(),
      showLoading: true,
      updatedAt: null,
      payload: {},
    };
  },

  beforeMount() {
    CampanhaNacionalSetFormDoencas();
    CampanhaNacionalSetFormGrupos();
  },
  mounted() {
    CampanhaNacionalSetInit({
      idUsuarioLogado: this.userData.id,
    });

    this.showLoading = false;
  },
  methods: {},
  computed: {
    campanhaNacionalState() {
      return CampanhaNacionalGetState();
    },
    preencheuDadosMinimos() {
      return CampanhaNacionalGetPreencheuDadosMinimos();
    },
    vacinas() {
      return CampanhaNacionalGetVacinasSelecionada();
    },
  },
  watch: {},
};
</script>

<style lang="scss">
@import "@/views/custom-pages/campanha-nacional/campanhaNacional.scss";
</style>