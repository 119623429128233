<template>
  <section id="group-pricing-dose-distribuition">
    <b-tabs class="custom-tab-header">
      <b-tab
        v-for="(vacina, index) in vacinas"
        :key="vacina.id_vacina"
        :active="index == 0"
        title-link-class="font-tab"
        title-item-class="custom-tab-style"
      >
        <template #title>
          <h4 class="d-sm-inline m-0">{{ vacina.nome_tecnico }}</h4>
        </template>

        <b-card class="shadow-none">
          <form-wizard
            color="#2772C0"
            :title="null"
            :subtitle="null"
            class="steps-transparent mb-3 col-9"
          >
            <tab-content title=" Grupos" icon="feather icon-box" :before-change="() => changeTabGroup(vacina.id_vacina)">
              <TabGroup :vacina="vacina" @groupComplete="setGroupComplete"/>
            </tab-content>

            <tab-content title=" Precificação" icon="feather icon-dollar-sign" :before-change="() => changeTabPrice(vacina.id_vacina)">
              <TabPrecificacao :vacina="vacina" @priceComplete="setPriceComplete"/>
            </tab-content>

            <tab-content title="Distribuição" icon="feather icon-disc">
              <TabDistribuicao :vacina="vacina" />
            </tab-content>

            <template slot="footer" slot-scope="props">
              <ButtonsSteps :stepsData="props"/>
            </template>
          </form-wizard>
        </b-card>
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
import { BButton, BTab, BTabs, BCard } from "bootstrap-vue";
import { FormWizard, TabContent } from "vue-form-wizard";

import TabGroup from "./TabGroup.vue";
import TabPrecificacao from "./TabPrecificacao.vue";
import TabDistribuicao from "./TabDistribuicao.vue";
import ButtonsSteps from "@/views/components/custom/ButtonsCampanha/ButtonSteps.vue";

export default {
  components: {
    BButton,
    BTab,
    BTabs,
    BCard,
    FormWizard,
    TabContent,
    TabGroup,
    TabPrecificacao,
    TabDistribuicao,
    ButtonsSteps
  },

  props: {
    vacinas: {
      required: true,
      type: Array,
    },
  },

  data() {
    return {
      groupComplete: [],
      priceComplete: []
    };
  },

  methods: {
    changeTabGroup(vacina) {
      const group = this.groupComplete.filter(group => group.id_vacina == vacina);
      if(group.length){
        return group[0].completo;
      }else{
        return false;
      }
    },
    changeTabPrice(vacina) {
      const price = this.priceComplete.filter(group => group.id_vacina == vacina);

      if(price.length){
        return price[0].completo;
      }else{
        return false;
      }
    },
    setGroupComplete(val) {
      this.groupComplete[val.id_vacina] = val;
    },
    setPriceComplete(val) {
      this.priceComplete[val.id_vacina] = val;
    }
  }
};
</script>

<style>
  .adjustInput {
    margin-left: -4px;
  }

  .adjustIconInputValue {
    z-index: 100;
  }
</style>
