<template>
  <div :key="updatedAt">
    <b-row v-if="grupos.length == 0">
      <b-col>
        <h5>Por favor, selecione um ou mais grupos</h5>
      </b-col>
    </b-row>

    <app-collapse v-else>
      <app-collapse-item
        v-for="(grupo, index) in grupos"
        :key="grupo.id_grupo"
        :is-visible="index == 0"
        title="Grupo"
        class="mb-2 shadow rounded"
      >
        <template slot="header">
          <TabPrecificacaoHeader
            :vacina="vacina"
            :grupo="grupo"
            @priceComplete="setPriceComplete"
          />
        </template>

        <b-row class="py-2 px-1">
          <b-col
            lg="6"
            md="6"
            sm="12"
            class="border-right"
          >
            <TabPrecificacaoValorEmpresa
              :money-mask="moneyMask"
              :vacina="vacina"
              :grupo="grupo"
            />

            <!-- operadores -->
            <div
              v-for="operador in grupo.operador"
              :key="operador.id_departamento"
            >
              <TabPrecificacaoValorOperador
                :money-mask="moneyMask"
                :vacina="vacina"
                :grupo="grupo"
                :operador="operador"
              />
            </div>
          </b-col>
          <b-col
            lg="6"
            md="6"
            sm="12"
          >
            <p class="m-0">
              Documentação e contratos
            </p>
            <TabPrecificacaoDocumento
              :vacina="vacina"
              :grupo="grupo"
            />
          </b-col>
        </b-row>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import {
  BAlert,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BCollapse,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BForm,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  CampanhaNacionalGetGruposSelecionadoPorVacina,
  CampanhaNacionalGetState,
} from '@/views/custom-pages/campanha-nacional/Helpers/campanhaNacionalHelpers.js'
import Ricon from '@/assets/images/icons/RIcon.svg'
import TabPrecificacaoValorEmpresa from './TabPrecificacaoValorEmpresa.vue'
import TabPrecificacaoValorOperador from './TabPrecificacaoValorOperador.vue'
import TabPrecificacaoDocumento from './TabPrecificacaoDocumento.vue'
import TabPrecificacaoHeader from './TabPrecificacaoHeader.vue'

export default {
  components: {
    BAlert,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCol,
    BCollapse,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BImg,
    BInputGroup,
    BInputGroupPrepend,
    BRow,
    BForm,
    AppCollapse,
    AppCollapseItem,
    TabPrecificacaoValorEmpresa,
    TabPrecificacaoValorOperador,
    TabPrecificacaoDocumento,
    TabPrecificacaoHeader,
  },
  props: {
    vacina: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      Ricon,
      moneyMask: {
        decimal: ',',
        thousands: '.',
        precision: 2,
      },
      updatedAt: null,
      grupos: [],
      priceCompleteByGroup: [],
    }
  },
  watch: {
    '$store.state.campanhaNacionalState.watchers.grupos': {
      immediate: false,
      handler() {
        this.grupos = CampanhaNacionalGetGruposSelecionadoPorVacina(
          this.vacina,
        )
        this.updatedAt = CampanhaNacionalGetState().updatedAt
      },
    },
  },
  methods: {
    setPriceComplete(val) {
      this.priceCompleteByGroup[val.id_grupo] = val

      const valoresCompletos = this.priceCompleteByGroup.filter(price => price.completo)

      if (valoresCompletos.length == this.grupos.length) {
        this.$emit('priceComplete', { id_vacina: this.vacina.id_vacina, completo: true })
      } else {
        this.$emit('priceComplete', { id_vacina: this.vacina.id_vacina, completo: false })
      }
    },
  },
}
</script>
